import React, { useEffect, useRef, useState } from 'react';
import { format } from 'date-fns';
import './Ticker.scss';

const EventTicker = ({ items = [], speed = 50, width = 0 }) => {
  const tickerContainerRef = useRef(null);
  const tickerTrackRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const isPaused = useRef(false);
  const dragStartX = useRef(0);
  const requestRef = useRef();
  const previousTimeRef = useRef();
  const [translateX, setTranslateX] = useState(0);
  const totalWidthRef = useRef(0);
  const containerWidthRef = useRef(0);
  const [isInitialized, setIsInitialized] = useState(false);

  // Calculate the total width of the items
  const calculateWidths = () => {
    if (tickerTrackRef.current && tickerContainerRef.current) {
      const itemsArray = Array.from(tickerTrackRef.current.children);
      const totalWidth = itemsArray.reduce((acc, item) => {
        const style = window.getComputedStyle(item);
        const marginRight = parseFloat(style.marginRight) || 0;
        return acc + item.offsetWidth + marginRight;
      }, 0);
      totalWidthRef.current = totalWidth;

      // Get container width
      containerWidthRef.current = tickerContainerRef.current.offsetWidth;

      // Set initial translateX to start from off the right side
      setTranslateX(containerWidthRef.current);

      // Mark as initialized to render items visibly
      setIsInitialized(true);
    }
  };

  // Recalculate widths on mount and when items or width change
  useEffect(() => {
    calculateWidths();

    const handleResize = () => {
      const prevContainerWidth = containerWidthRef.current;
      calculateWidths();
      // Adjust translateX based on the change in container width
      setTranslateX((prev) => prev + (containerWidthRef.current - prevContainerWidth));
    };

    window.addEventListener('resize', handleResize);

    previousTimeRef.current = performance.now();
    requestRef.current = requestAnimationFrame(animate);

    return () => {
      cancelAnimationFrame(requestRef.current);
      window.removeEventListener('resize', handleResize);
    };
  }, [items, width, speed]);

  // Animation loop
  const animate = (time) => {
    if (previousTimeRef.current !== undefined && !isPaused.current) {
      const deltaTime = time - previousTimeRef.current;
      const distance = (speed * deltaTime) / 1000; // pixels per millisecond

      setTranslateX((prev) => {
        let newTranslateX = prev - distance;

        // If the entire ticker track has moved past the left edge, reset to start position
        if (newTranslateX <= -totalWidthRef.current) {
          newTranslateX = containerWidthRef.current;
        }

        return newTranslateX;
      });
    }
    previousTimeRef.current = time;
    requestRef.current = requestAnimationFrame(animate);
  };

  // Handle dragging
  const handleDragStart = (clientX) => {
    isPaused.current = true;
    setIsDragging(true);
    dragStartX.current = clientX;
    tickerContainerRef.current.classList.add('grabbing');
  };

  const handleDragMove = (clientX) => {
    const dx = clientX - dragStartX.current;
    dragStartX.current = clientX;
    setTranslateX((prev) => prev + dx);
  };

  const handleDragEnd = () => {
    isPaused.current = false;
    setIsDragging(false);
    tickerContainerRef.current.classList.remove('grabbing');
  };

  // Mouse events
  useEffect(() => {
    const handleMouseDown = (e) => {
      handleDragStart(e.clientX);
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
      e.preventDefault();
    };

    const handleMouseMove = (e) => {
      handleDragMove(e.clientX);
    };

    const handleMouseUp = () => {
      handleDragEnd();
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    tickerContainerRef.current.addEventListener('mousedown', handleMouseDown);

    return () => {
      tickerContainerRef.current.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

  // Touch events
  useEffect(() => {
    const handleTouchStart = (e) => {
      handleDragStart(e.touches[0].clientX);
      document.addEventListener('touchmove', handleTouchMove, { passive: false });
      document.addEventListener('touchend', handleTouchEnd);
      e.preventDefault();
    };

    const handleTouchMove = (e) => {
      handleDragMove(e.touches[0].clientX);
      e.preventDefault();
    };

    const handleTouchEnd = () => {
      handleDragEnd();
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };

    tickerContainerRef.current.addEventListener('touchstart', handleTouchStart, {
      passive: false,
    });

    return () => {
      tickerContainerRef.current.removeEventListener('touchstart', handleTouchStart);
    };
  }, []);

  return (
    <div
      className="ticker-container"
      ref={tickerContainerRef}
      style={{ width: width > 992 ? width - 300 : width }}
    >
      <div
        className="ticker-track"
        ref={tickerTrackRef}
        style={{
          transform: `translateX(${translateX}px)`,
          visibility: isInitialized ? 'visible' : 'hidden',
        }}
      >
        {items.map((item, index) => (
          <div key={index} className="ticker-item" onClick={(e) => e.stopPropagation()}>
            <div className="event-date">
              {format(new Date(item.timestamp.seconds * 1000), 'EEEE, MMMM do')}
            </div>
            <div className="event-title">{item.title}</div>
            <div className="event-description">{item.description}</div>
            <div className="event-location">{item.locationName}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EventTicker;
