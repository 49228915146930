// src/components/LessonInterestForm/index.jsx

import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input/input';
import 'react-phone-number-input/style.css';
import './index.scss';

const LessonInterestForm = (props) => {
  const { firebase, setIsLoading, handleSubmission, isLoading } =
    props;

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [locationPreference, setLocationPreference] = useState('');
  const [timePreference, setTimePreference] = useState('');
  const [notes, setNotes] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const formIsInvalid = () => {
    return (
      !name ||
      !phone ||
      !email ||
      !locationPreference ||
      !timePreference
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (formIsInvalid()) {
      setErrorMessage('Please fill out all required fields.');
      return;
    }

    try {
      setIsLoading(true);
      setErrorMessage('');

      const send_email = firebase.sendEmail();
      // emailTo: ['lessons@getinthebunker.golf'],

      const payload = {
        emailTo: ['sean@getinthebunker.golf'],
        template: 'lesson_template',
        name,
        phone,
        email,
        locationPreference,
        timePreference,
        notes,
      };

      await send_email(payload, { auth: true });

      // Inform parent component of successful submission
      handleSubmission('success');

      // Reset form fields upon successful submission
      setName('');
      setPhone('');
      setEmail('');
      setLocationPreference('');
      setTimePreference('');
      setNotes('');
    } catch (error) {
      console.error(error);
      setErrorMessage(
        'An error occurred while submitting the form. Please try again later.',
      );

      // Inform parent component of failure
      handleSubmission('error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Form
      className="lesson-interest-form p-3"
      onSubmit={handleSubmit}
    >
      {errorMessage && (
        <div className="text-danger mb-3">{errorMessage}</div>
      )}

      <Form.Group controlId="name" className="mb-3">
        <Form.Label>Name:</Form.Label>
        <Form.Control
          type="text"
          value={name}
          onChange={(event) => setName(event.target.value)}
          required
        />
      </Form.Group>

      <Form.Group controlId="phone" className="mb-3">
        <Form.Label>Phone Number:</Form.Label>
        <Form.Control
          as={PhoneInput}
          country="US"
          value={phone}
          onChange={setPhone}
          required
        />
      </Form.Group>

      <Form.Group controlId="email" className="mb-3">
        <Form.Label>Email Address:</Form.Label>
        <Form.Control
          type="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          required
        />
      </Form.Group>

      <Form.Group controlId="locationPreference" className="mb-3">
        <Form.Label>Location Preference:</Form.Label>
        <Form.Control
          as="select"
          value={locationPreference}
          onChange={(event) =>
            setLocationPreference(event.target.value)
          }
          required
        >
          <option value="">Select a Location</option>
          <option value="Guilderland, NY">Guilderland, NY</option>
          <option value="Clifton Park, NY">Clifton Park, NY</option>
          <option value="North Greenbush, NY">
            North Greenbush, NY
          </option>
          <option value="Latham, NY">Latham, NY</option>
          <option value="Other">Other</option>
        </Form.Control>
      </Form.Group>

      <Form.Group controlId="timePreference" className="mb-3">
        <Form.Label>Time of Day Preference:</Form.Label>
        <Form.Control
          as="select"
          value={timePreference}
          onChange={(event) => setTimePreference(event.target.value)}
          required
        >
          <option value="">Select a Time of Day</option>
          <option value="Morning">Morning</option>
          <option value="Afternoon">Afternoon</option>
          <option value="Evening">Evening</option>
        </Form.Control>
      </Form.Group>

      <Form.Group controlId="notes" className="mb-3">
        <Form.Label>Notes:</Form.Label>
        <Form.Control
          as="textarea"
          value={notes}
          onChange={(event) => setNotes(event.target.value)}
        />
      </Form.Group>

      <Button
        variant="success"
        type="submit"
        disabled={isLoading}
        className="mt-3"
      >
        Submit
      </Button>
    </Form>
  );
};

export default LessonInterestForm;
