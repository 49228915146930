import React, { useState } from 'react';
import { Footer } from '../Landing';
import { withFirebase } from '../../components/Firebase';
import { useAlert } from 'react-alert';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/react';
import { useForm } from 'react-hook-form';
// import { sendEmail } from '../../utils/email';
import { formatPhone } from '../../utils/format';
import '../../utils/shared.scss';
import './index.scss';
import { Row, Col } from 'react-bootstrap';

const EMAIL_REGEX =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
const TEXT_REGEX = /^[a-zA-Z]+$/;
const TEXTBOX_REGEX = /^[a-zA-Z0-9\s.,!?()'"-]+$/;
const PHONE_REGEX =
  /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/; // Updated regex for US phone numbers

const HEADER = 'LEAGUES';
const TITLE = 'Want to host a league at The Bunker?';
const SUBTITLE =
  'Contact us using the form below. We look forward to serving you!';

function convertTo12HourFormat(time) {
  let [hours, minutes] = time.split(':');
  let period = +hours < 12 ? 'AM' : 'PM';

  if (hours === '00') {
    hours = '12';
  } else if (+hours > 12) {
    hours = String(+hours - 12).padStart(2, '0');
  }

  return `${hours}:${minutes} ${period}`;
}

const LeaguesPage = (props) => {
  let [loading, setLoading] = useState(false);

  const alert = useAlert();

  const Title = (
    <div className="title-container">
      <div className="overlay">
        <h1 className="title text-white m-0">{HEADER}</h1>
      </div>
      <img
        src="https://storage.googleapis.com/thebunker-assets/thebunker/bunker-leagues-header.jpg"
        width={'100%'}
        height={'100%'}
      />
    </div>
  );

  const Form = () => {
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
      setLoading(true);
			const formattedTime = convertTo12HourFormat(data.preferredTime);

      const payload = {
        name: data.firstName + ' ' + data.lastName,
        email: data.email,
        phone: formatPhone(data.phone),
        players: data.players,
        message: data.message,
        location: data.location,
        season: data.season,
        preferredTime: formattedTime,
        emailTo: ['leagues@getinthebunker.golf'],
        // emailTo: ['codyhusek@gmail.com'],
        template: 'league_template',
      };

      const sendEmail_ = props.firebase.sendEmail();

      try {
        const result = await sendEmail_(payload, { auth: true });
        alert.success('Your inquiry has been sent to The Bunker!');
      } catch (error) {
        console.error(error);
        alert.error('There was an error sending your inquiry.');
      } finally {
        setLoading(false);
      }
    };

    return (
      <div className="w-100 h-100 d-flex">
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          <div className="form-input">
            <label>First Name *</label>
            <input
              {...register('firstName', {
                required: 'First name is required',
                maxLength: {
                  value: 20,
                  message: 'First name cannot exceed 20 characters',
                },
                pattern: {
                  value: TEXT_REGEX,
                  message: 'First name can only contain letters',
                },
              })}
            />
            {errors.firstName && <p>{errors.firstName.message}</p>}
          </div>

          <div className="form-input">
            <label>Last Name *</label>
            <input
              {...register('lastName', {
                required: 'Last name is required',
                pattern: {
                  value: TEXT_REGEX,
                  message: 'Last name can only contain letters',
                },
              })}
            />
            {errors.lastName && <p>{errors.lastName.message}</p>}
          </div>

          <div className="form-input">
            <label>Phone Number *</label>
            <input
              type="phone"
              {...register('phone', {
                required: 'Phone number is required',
                pattern: {
                  value: PHONE_REGEX,
                  message: 'Invalid phone number format',
                },
              })}
            />
            {errors.phone && <p>{errors.phone.message}</p>}
          </div>

          <div className="form-input">
            <label>Email Address *</label>
            <input
              type="email"
              {...register('email', {
                required: 'Email address is required',
                pattern: {
                  value: EMAIL_REGEX,
                  message: 'Invalid email address format',
                },
              })}
            />
            {errors.email && <p>{errors.email.message}</p>}
          </div>

          <div className="form-input">
            <label>Location *</label>
            <select
              {...register('location', {
                required: 'Please select a location',
              })}
              defaultValue="" // Set the default value to an empty string
            >
              <option key={1} value="" disabled>
                Select a location
              </option>
              <option key={2} value="Mohawk Harbor">
                Mohawk Harbor
              </option>
              <option key={3} value="Clifton Park">
                Clifton Park
              </option>
              <option key={4} value="Guilderland">
                Guilderland
              </option>
              <option key={5} value="New Hartford">
                New Hartford
              </option>
              <option key={6} value="North Greenbush">
                North Greenbush
              </option>
              <option key={7} value="Saratoga">
                Saratoga
              </option>
            </select>
            {errors.location && <p>{errors.location.message}</p>}
          </div>

          <div className="form-input">
            <label>Preferred Time *</label>
            <input
              type="time"
              {...register('preferredTime', {
                required: 'Preferred time is required',
              })}
            />
            {errors.preferredTime && (
              <p>{errors.preferredTime.message}</p>
            )}
          </div>

          <div className="form-input">
            <label>Season *</label>
            <select
              {...register('season', {
                required: 'Please select a season',
              })}
              defaultValue="" // Set the default value to an empty string
            >
              <option value="" disabled>
                Select a season
              </option>
              <option value="Pre-Season">Pre-Season</option>
              <option value="In-Season">In-Season</option>
            </select>
            {errors.season && <p>{errors.season.message}</p>}
          </div>

          <div className="form-input">
            <label>Number of Players</label>
            <select
              {...register('players', {
                required: 'Number of players is required',
              })}
            >
              {[...Array(13).keys()].map((num) => {
                const value = num + 0; // Adjusting the value to start from 8
                return (
                  value <= 20 && (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  )
                );
              })}
            </select>
            {errors.players && <p>{errors.players.message}</p>}
          </div>

          <div className="form-input textarea">
            <label>Message</label>
            <textarea
              {...register('message', {
                // required: 'Message is required',
                pattern: {
                  value: TEXTBOX_REGEX,
                  message:
                    'Message can only contain letters, numbers, and basic punctuation',
                },
              })}
            ></textarea>
            {errors.message && <p>{errors.message.message}</p>}
          </div>

          <button className="submit grid-span-2" type="submit">
            Submit
          </button>
        </form>
      </div>
    );
  };

  const Main = () => {
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: red;
    `;

    return (
      <div className="main px-3 flex-fill ">
        <div className="pt-4 flex flex-wrap">
          {/* <div className="d-flex justify-content-around"> */}
          {/* <div> */}
          <span className="text-center font-weight-bold h4 padding m-0 my-3 align-items-center justify-content-center d-flex h-100 mb-5">
            Bunker leagues include a course schedule for the season,
            TRACKMAN handicaps, make-up days, season long prizes and drink
            specials.
          </span>

          {/* <div>
            <p className="font-weight-bold text-dark text-center h4 my-4">
              Weekly prizes and drink specials!
            </p>

            <p className="font-weight-bold text-dark text-center h4 my-4">
              Course schedule for season
            </p>
            <p className="font-weight-bold text-dark text-center h4 my-4">
              TRACKMAN Handicaps
            </p>
            <p className="font-weight-bold text-dark text-center h4 my-4 mb-5 pb-2">
              Make up days
            </p>
          </div> */}
          {/* </div> */}
          {/* <div className="padding">
            <hr />
          </div> */}
          <p className="text-primary text-center font-weight-bold h3 my-3">
            PRE-SEASON
          </p>
          <div className="padding">
            <Row className="d-flex flex-wrap">
              <Col md={4} xs={12} className="text-center h4 mb-4">
                <p className="m-0 font-weight-bold">DATES:</p>
                <p className="m-0">10/7-11/28</p>
              </Col>
              <Col md={4} xs={12} className="text-center h4 mb-4">
                <p className="m-0 font-weight-bold">TEE TIMES:</p>
                <p className="m-0">Mon/Tues/Wed Nights</p>
                <p className="m-0">5-7:15 pm, 7:15-9:30 pm</p>
              </Col>
              <Col md={4} xs={12} className="text-center h4 mb-4">
                <p className="m-0 font-weight-bold">GREENS FEES:</p>
                <p className="m-0">$240 for 8 weeks of play</p>
              </Col>
            </Row>
          </div>
          <div className="padding">
            <hr />
          </div>
          <p className="text-primary text-center font-weight-bold h3 my-3">
            IN-SEASON
          </p>
          <div className="padding">
            <Row className="d-flex flex-wrap">
              <Col md={4} xs={12} className="text-center">
                <div className="py-6 h4 mb-4">
                  <p className="m-0 font-weight-bold">DATES:</p>
                  <p className="m-0">1/6-3/27</p>
                </div>
              </Col>
              <Col md={4} xs={12} className="text-center h4 mb-4">
                <p className="m-0 font-weight-bold">TEE TIMES:</p>
                <p className="m-0">Mon/Tues/Wed Nights</p>
                <p className="m-0">5-7:15 pm, 7:15-9:30 pm</p>
              </Col>
              <Col md={4} xs={12} className="text-center h4 mb-4">
                <p className="m-0 font-weight-bold">GREENS FEES:</p>
                <p className="m-0">$360 for 12 weeks of play</p>
              </Col>
            </Row>
          </div>

          <p className="text-primary text-center font-weight-bold h3">
            Interested in creating or joining a league?
          </p>

          <p className=" text-center text-sm">
            Complete the form below and we'll reach out with more
            info!
          </p>
        </div>
        {loading ? (
          <ClipLoader loading={loading} css={override} size={150} />
        ) : (
          <Form />
        )}
      </div>
    );
  };

  return (
    <div className="leagues-container">
      {Title}
      <Main />
      {/* <Footer /> */}
    </div>
  );
};

export default withFirebase(LeaguesPage);
