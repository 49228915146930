import React from 'react';

import TB_CP_MENU from '../../assets/menus/Bunker_Menu_NG_CP_20240904.pdf';
import TB_NG_MENU from '../../assets/menus/Bunker_Menu_NG_CP_20240904.pdf';
import TB_MH_MENU from '../../assets/menus/Bunker_MH_TheGreen_Menu_20240823.pdf';
import TB_MH_DESSERT_MENU from '../../assets/menus/Bunker_MH_TheGreen_Dessert_Menu_WEB_20240508.pdf';
import TB_NH_MENU from '../../assets/menus/Bunker_Menu_WEB_NH_20240506.pdf';
import TB_G_MENU from '../../assets/menus/Bunker_G_Menu_20230911.pdf';
import TB_S_MENU from '../../assets/menus/Bunker_Saratoga_Menu_20240904.pdf';
import TB_S_DESSERT_MENU from '../../assets/menus/Bunker_Saratoga_Menu_Dessert.pdf';
import TB_S_KIDS_MENU from '../../assets/menus/Bunker_Saratoga_Kids_Menu_20240313.pdf';
import TB_KIDS_MENU from '../../assets/menus/Bunker_Kids_Menu.pdf';
import TB_CP_BRUNCH_MENU from '../../assets/menus/Bunker Brunch MH, cp, nh, ng (1).pdf';
import TB_MH_BRUNCH_MENU from '../../assets/menus/Bunker_Brunch_Saratoga_MohawkHarbor.pdf';
import TB_NH_BRUNCH_MENU from '../../assets/menus/Bunker Brunch MH, cp, nh, ng (1).pdf';
import TB_NG_BRUNCH_MENU from '../../assets/menus/Bunker Brunch MH, cp, nh, ng (1).pdf';
import TB_S_BRUNCH_MENU from '../../assets/menus/Bunker_Brunch_Saratoga_MohawkHarbor.pdf';
import TB_CP_SPECIALTY_DRINKS_MENU from '../../assets/menus/Bunker_WEB_Summer_Specialty_Drink_Menu_20240423 (1).pdf';
import TB_MH_SPECIALTY_DRINKS_MENU from '../../assets/menus/Bunker_WEB_Summer_Specialty_Drink_Menu_20240423 (1).pdf';
import TB_NH_SPECIALTY_DRINKS_MENU from '../../assets/menus/Bunker_WEB_Summer_Specialty_Drink_Menu_20240423 (1).pdf';
import TB_NG_SPECIALTY_DRINKS_MENU from '../../assets/menus/Bunker_WEB_Summer_Specialty_Drink_Menu_20240423 (1).pdf';
import TB_S_SPECIALTY_DRINKS_MENU from '../../assets/menus/Bunker_WEB_Saratoga_Summer_Specialty_Drink_Menu_20240423 (3).pdf';
import TrackmanBaysSvg from '../../assets/icons/TRACKMAN_Bays.svg';
import OutdoorSeatingSvg from '../../assets/icons/Outdoor_Seating.svg';
import IndoorOutdoorBarSvg from '../../assets/icons/Indoor-Outdoor_Bar.svg';
import FullBarSvg from '../../assets/icons/Full_Bar.svg';
import FullFoodSvg from '../../assets/icons/Full_Food.svg';
import LightFoodSvg from '../../assets/icons/Light_Food.svg';
import DartsSvg from '../../assets/icons/Darts.svg';
import CateringSvg from '../../assets/icons/Catering.svg';
import GolfLessonsSvg from '../../assets/icons/Golf_Lessons.svg';
import EventsSvg from '../../assets/icons/Events.svg';
import GiftCardsSvg from '../../assets/icons/Gift_Cards.svg';
import HappyHourSvg from '../../assets/icons/Happy_Hour.svg';
import LoungeSvg from '../../assets/icons/Lounge.svg';
import OutdoorGrillSvg from '../../assets/icons/Outdoor_Grill.svg';
import TwoFloorsSvg from '../../assets/icons/2_Floors.svg';
import dessert_menu from '../../assets/menus/dessert_menu.pdf';

const cliftonpark_main =
  'https://storage.googleapis.com/thebunker-assets/thebunker/clifton-park/main.png';
const cliftonpark_1 =
  'https://storage.googleapis.com/thebunker-assets/thebunker/clifton-park/one.png';
const cliftonpark_2 =
  'https://storage.googleapis.com/thebunker-assets/thebunker/clifton-park/two.png';
const cliftonpark_3 =
  'https://storage.googleapis.com/thebunker-assets/thebunker/clifton-park/three.png';
const cliftonpark_4 =
  'https://storage.googleapis.com/thebunker-assets/thebunker/clifton-park/four.png';

const guilderland_main =
  'https://storage.googleapis.com/thebunker-assets/thebunker/guilderland/main.png';
const guilderland_1 =
  'https://storage.googleapis.com/thebunker-assets/thebunker/guilderland/one.png';
const guilderland_2 =
  'https://storage.googleapis.com/thebunker-assets/thebunker/guilderland/two.png';
const guilderland_3 =
  'https://storage.googleapis.com/thebunker-assets/thebunker/guilderland/three.png';
const guilderland_4 =
  'https://storage.googleapis.com/thebunker-assets/thebunker/guilderland/four.png';

// const latham_main =
//   'https://storage.googleapis.com/thebunker-assets/thebunker/latham/main.png';
// const latham_1 =
//   'https://storage.googleapis.com/thebunker-assets/thebunker/latham/one.png';
// const latham_2 =
//   'https://storage.googleapis.com/thebunker-assets/thebunker/latham/two.png';
// const latham_3 =
//   'https://storage.googleapis.com/thebunker-assets/thebunker/latham/three.png';
// const latham_4 =
//   'https://storage.googleapis.com/thebunker-assets/thebunker/latham/four.png';

const northgreenbush_1 =
  'https://storage.googleapis.com/thebunker-assets/thebunker/north-greenbush/one.png';
const northgreenbush_2 =
  'https://storage.googleapis.com/thebunker-assets/thebunker/north-greenbush/two.png';
const northgreenbush_3 =
  'https://storage.googleapis.com/thebunker-assets/thebunker/north-greenbush/three.png';
const northgreenbush_4 =
  'https://storage.googleapis.com/thebunker-assets/thebunker/north-greenbush/four.png';
const northgreenbush_main =
  'https://storage.googleapis.com/thebunker-assets/thebunker/north-greenbush/main.png';

const newhartford_1 =
  'https://storage.googleapis.com/thebunker-assets/thebunker/new-hartford/one.png';
const newhartford_2 =
  'https://storage.googleapis.com/thebunker-assets/thebunker/new-hartford/two.png';
const newhartford_3 =
  'https://storage.googleapis.com/thebunker-assets/thebunker/new-hartford/three.png';
const newhartford_4 =
  'https://storage.googleapis.com/thebunker-assets/thebunker/new-hartford/four.png';
const newhartford_main =
  'https://storage.googleapis.com/thebunker-assets/thebunker/new-hartford/main.png';

const mohawkharbor_1 =
  'https://storage.googleapis.com/thebunker-assets/thebunker/mohawkharbor/one.png';
const mohawkharbor_2 =
  'https://storage.googleapis.com/thebunker-assets/thebunker/mohawkharbor/two.png';
const mohawkharbor_3 =
  'https://storage.googleapis.com/thebunker-assets/thebunker/mohawkharbor/three.png';
const mohawkharbor_4 =
  'https://storage.googleapis.com/thebunker-assets/thebunker/mohawkharbor/four.png';
const mohawkharbor_main =
  'https://storage.googleapis.com/thebunker-assets/thebunker/mohawkharbor/main.png';

const locations = [
  /*
   *    Clifton Park 0
   */
  {
    images: [
      cliftonpark_main,
      cliftonpark_1,
      cliftonpark_2,
      cliftonpark_3,
      cliftonpark_4,
    ],
    id: 'cliftonpark',
    menus: [
      // { pdf: TB_CP_BRUNCH_MENU, name: 'Brunch Menu' },
      { pdf: TB_CP_MENU, name: 'Main Menu' },
      { pdf: dessert_menu, name: 'Dessert Menu' },
      {
        pdf: TB_KIDS_MENU,
        name: 'Kids Menu',
      },
      { pdf: TB_CP_SPECIALTY_DRINKS_MENU, name: 'Specialty Drinks' },
    ],
    url: 'https://thebunker-teefindr-live.web.app/location/thebunker/thebunkercliftonpark-yjGcFjTiX8v9MBbsq7HC',
    email: 'info@GetInTheBunker.golf',
    name: 'Clifton Park',
    address: '19 Clifton Country Rd, Clifton Park, NY 12065',
    phone: '(518) 280-6347',
    newItems: true,
    // hoursFull: 'Tue-Thur 4p-11p, Fri-Sat 11a-11p, Sun 12p-8p',
    // hoursFull: 'Mon-Sat 9am-11pm Sun 9am-8pm',
    hoursFull: 'Closed Sun & Mon, Tue-Thur 4p-10p, Fri 3p-11p, Sat 10a-11p',
    // hoursShort: 'Mon-Sat: 9am-11pm, Sun: 9am-8pm',
    // leaguesShort:"3-5pm, 5:20-7:20pm, and 7:40-close",
    nonPeakRates: {
      // range: 'Mon - Fri 9am - 3pm',
      range: '',
      bays: [
        { name: 'Bay 1', price: '$60 per hour' },
        { name: 'Bay 2', price: '$60 per hour' },
        { name: 'Bay 3', price: '$50 per hour' },
        { name: 'Bay 4', price: '$50 per hour' },
        { name: 'Bay 5', price: '$50 per hour' },
        { name: 'VIP Suite (Private)', price: '$100 per hour' },
        {
          name: 'Darts',
          price: '$15/guest/hour with a maximum of $60/hour',
        },
      ],
    },
    peakRates: {
      // range: 'Mon - Fri 3pm - 11pm (Sat, Sun & Holidays All Day)',
      range: '',
      bays: [
        { name: 'Bay 1', price: '$70 per hour' },
        { name: 'Bay 2', price: '$70 per hour' },
        { name: 'Bay 3', price: '$60 per hour' },
        { name: 'Bay 4', price: '$60 per hour' },
        { name: 'Bay 5', price: '$60 per hour' },
        { name: 'VIP Suite (Private)', price: '$100 per hour' },
        {
          name: 'Darts',
          price: '$15/guest/hour with a maximum of $60/hour',
        },
      ],
    },
    mealeo: 'https://order.getinthebunker.menu/r/67282',
    coordinates: { lat: 42.85883117180785, lng: -73.78237199029 },
    promotions: [
      {
        title: 'VIP Suite Discount',
        body: 'VIP Suite $70 Monday-Thursday with 4 players or less',
      },
    ],
    amenities: [
      { icon: TrackmanBaysSvg, title: '6 TRACKMAN Bays' },
      { icon: IndoorOutdoorBarSvg, title: 'Indoor/Outdoor Bar' },
      { icon: OutdoorSeatingSvg, title: 'Outdoor Seating' },
      { icon: FullBarSvg, width: '80px', title: 'Full Bar' },
      { icon: FullFoodSvg, title: 'Full Food Menu' },
      { icon: DartsSvg, title: 'Darts' },
      { icon: CateringSvg, width: '75px', title: 'Catering' },
      { icon: GolfLessonsSvg, title: 'Golf Lessons' },
      { icon: EventsSvg, title: 'Events' },
      { icon: GiftCardsSvg, title: 'Gift Cards' },
      { icon: HappyHourSvg, title: 'Happy Hour' },
    ],
  },

  /*
   *    Guilderland 1
   */
  {
    images: [
      guilderland_main,
      guilderland_1,
      guilderland_2,
      guilderland_3,
      guilderland_4,
    ],
    id: 'guilderland',
    menus: [{ pdf: TB_G_MENU, name: 'Main Menu' }],
    url: 'https://thebunker-teefindr-live.web.app/location/thebunker/thebunkerguilderland-ImZXmsKGBwwQXrJubMkl',
    email: 'info@GetInTheBunker.golf',
    name: 'Guilderland',
    address: '2390 Western Ave, Guilderland, NY 12084',
    phone: '(518) 867-3008',
    // hoursFull: 'Closed (Summer Hours)',
    // hoursFull: 'Mon-Sat 9am-11pm Sun 9am-8pm',
    hoursFull: 'Closed 4/15 – 10/15',
    // hoursFull: 'Tue-Thur 4p-11p, Fri-Sat 11a-11p, Sun 11a-6p',
    // hoursShort: 'Mon-Sat: 9am-11pm, Sun: 9am-8pm',
    // hoursFull: '24 Hour Notice for tee times/ Events',
    // leaguesShort:"3-5pm, 5:20-7:20pm, and 7:40-close",
    nonPeakRates: {
      // range: 'Mon - Fri 9am - 3pm',
      range: '',
      bays: [
        { name: 'Bay 1', price: '$40 per hour' },
        { name: 'Bay 10', price: '$50 per hour' },
        { name: 'Bay 18', price: '$50 per hour' },
        { name: 'VIP Suite (Private)', price: '$70 per hour' },
        { name: 'Dutchmen Room (Private)', price: '$70 per hour' },
      ],
    },
    peakRates: {
      // range: 'Mon - Fri 3pm - 11pm (Sat, Sun & Holidays All Day)',
      range: '',
      bays: [
        { name: 'Bay 1', price: '$50 per hour' },
        { name: 'Bay 10', price: '$60 per hour' },
        { name: 'Bay 18', price: '$60 per hour' },
        { name: 'VIP Suite (Private)', price: '$70 per hour' },
        { name: 'Dutchmen Room (Private)', price: '$70 per hour' },
      ],
    },
    coordinates: { lat: 42.70660985151098, lng: -73.91846325308022 },
    promotions: [],
    amenities: [
      { icon: TrackmanBaysSvg, title: '5 TRACKMAN Bays' },
      { icon: FullBarSvg, width: '80px', title: 'Full Bar' },
      { icon: LightFoodSvg, title: 'Light Food Menu' },
      { icon: CateringSvg, width: '75px', title: 'Catering' },
      { icon: GolfLessonsSvg, title: 'Golf Lessons' },
      { icon: EventsSvg, title: 'Events' },
      { icon: GiftCardsSvg, title: 'Gift Cards' },
      { icon: LoungeSvg, width: '90px', title: 'Lounge and Bar' },
      { icon: HappyHourSvg, title: 'Happy Hour' },
    ],
  },

  /*
   *    Vent GPS - Latham 2
   */
  // {
  //   id: 'ventgps',
  //   images: [latham_main, latham_1, latham_2, latham_4, latham_3],
  //   url: 'https://thebunker-teefindr-live.web.app/location/thebunker/thebunkerlatham-AskSnSccZfU2QqdFkJYm',
  //   name: 'GPS Latham',
  //   email: 'info@GetInTheBunker.golf',
  //   address: '195 Troy-Schenectady, Latham, NY 12010',
  //   phone: '(518) 786-1500',
  //   nonPeakRates: null,
  //   peakRates: null,
  //   coordinates: { lat: 42.74036, lng: -73.73233 },
  //   hoursShort: 'See VENT FITNESS hours',
  //   hoursFull: 'See VENT FITNESS hours',
  //   about: true,
  //   promotions: [],
  //   amenities: [
  //     { icon: TrackmanBaysSvg, title: '1 Private TRACKMAN' },
  //     { icon: GolfLessonsSvg, title: 'Golf Lessons' },
  //   ],
  // },

  /*
   *    North Greenbush 3
   */
  {
    images: [
      northgreenbush_main,
      northgreenbush_1,
      northgreenbush_2,
      northgreenbush_3,
      northgreenbush_4,
    ],
    id: 'northgreenbush',
    menus: [
      // { pdf: TB_NG_BRUNCH_MENU, name: 'Brunch Menu' },
      { pdf: TB_NG_MENU, name: 'Main Menu' },
      { pdf: dessert_menu, name: 'Dessert Menu' },
      {
        pdf: TB_KIDS_MENU,
        name: 'Kids Menu',
      },
      { pdf: TB_NG_SPECIALTY_DRINKS_MENU, name: 'Specialty Drinks' },
    ],
    url: 'https://thebunker-teefindr-live.web.app/location/thebunker/thebunkernorthgreenbush-vF8VJfondldMD1q7uRWH',
    email: 'info@GetInTheBunker.golf',
    name: 'North Greenbush',
    address: '490 North Greenbush RD, Rensselaer, NY 12144',
    phone: '(518) 874 4018',
    // hoursFull: 'Tue-Thur 4p-11p, Fri-Sat 11a-11p, Sun 12p-8p',
    // hoursFull: 'Mon-Sat 9am-11pm Sun 9am-8pm',
    // hoursFull: 'Closed Mon-Tue, Wed-Thur 4p-10p, Fri 3p-11p, Sat 10a-11p, Sun 10a-2p (Brunch Only)',
		hoursFull: 'Closed Sun & Mon, Tue-Thur 4p-10p, Fri 3p-11p, Sat 10a-11p',
    // hoursShort: 'Mon-Sat: 9am-11pm, Sun: 9am-8pm',
    // notice: "Pending NYS Liquor License",
    // leaguesShort:"3-5pm, 5:20-7:20pm, and 7:40-close",
    nonPeakRates: {
      // range: 'Mon - Fri 9am - 3pm',
      range: '',
      bays: [
        { name: 'Bay 1', price: '$70 per hour' },
        { name: 'Viking Room (Private)', price: '$70 per hour' },
        { name: 'Bay 3', price: '$50 per hour' },
        { name: 'Bay 4', price: '$50 per hour' },
        { name: 'Bay 5', price: '$50 per hour' },
        { name: 'VIP Suite (Private)', price: '$100 per hour' },
        { name: 'Blue Devil Room (Private)', price: '$80 per hour' },
      ],
    },
    peakRates: {
      // range: 'Mon - Fri 3pm - 11pm (Sat, Sun & Holidays All Day)',
      range: '',
      bays: [
        { name: 'Bay 1', price: '$70 per hour' },
        { name: 'Viking Room (Private)', price: '$70 per hour' },
        { name: 'Bay 3', price: '$60 per hour' },
        { name: 'Bay 4', price: '$60 per hour' },
        { name: 'Bay 5', price: '$60 per hour' },
        { name: 'VIP Suite (Private)', price: '$100 per hour' },
        { name: 'Blue Devil Room (Private)', price: '$80 per hour' },
      ],
    },
    mealeo: 'https://order.getinthebunker.menu/r/67290',
    coordinates: { lat: 42.658434902970534, lng: -73.69341027159967 },
    promotions: [
      {
        title: 'VIP Suite Discount',
        body: 'VIP Suite $70 Monday-Thursday with 4 players or less',
      },
    ],
    amenities: [
      { icon: TrackmanBaysSvg, title: '7 TRACKMAN Bays' },
      { icon: IndoorOutdoorBarSvg, title: 'Indoor/Outdoor Bar' },
      { icon: TwoFloorsSvg, title: '2 Floors' },
      { icon: OutdoorSeatingSvg, title: 'Outdoor Seating' },
      { icon: FullBarSvg, width: '75px', title: 'Full Bar' },
      { icon: FullFoodSvg, title: 'Full Food Menu' },
      { icon: CateringSvg, width: '75px', title: 'Catering' },
      { icon: GolfLessonsSvg, title: 'Golf Lessons' },
      { icon: EventsSvg, title: 'Events' },
      { icon: GiftCardsSvg, title: 'Gift Cards' },
      { icon: HappyHourSvg, title: 'Happy Hour' },
    ],
  },

  /*
   *    New Hartford 4
   */
  {
    images: [
      newhartford_main,
      newhartford_1,
      newhartford_2,
      newhartford_3,
      newhartford_4,
    ],
    id: 'newhartford',
    menus: [
      // { pdf: TB_NH_BRUNCH_MENU, name: 'Brunch Menu' },
      { pdf: TB_NH_MENU, name: 'Main Menu' },
      { pdf: dessert_menu, name: 'Dessert Menu' },
      {
        pdf: TB_KIDS_MENU,
        name: 'Kids Menu',
      },
      { pdf: TB_NH_SPECIALTY_DRINKS_MENU, name: 'Specialty Drinks' },
    ],
    url: 'https://thebunker-teefindr-live.web.app/location/thebunker/thebunkernewhartford-siuP1h7mbGBIrJCsDVOb',
    email: 'info@GetInTheBunker.golf',
    name: 'New Hartford',
    address: '8653 Clinton St, New Hartford, NY 13413',
    phone: '(315) 864 3108',
    // hoursFull: 'Tue-Thur 4p-11p, Fri-Sat 11a-11p, Sun 12p-8p',
    // hoursFull: 'Mon-Sat 9am-11pm Sun 9am-8pm',
    hoursFull:
      'Closed Sun-Tue, Wed-Thur 4p-10p, Fri 4p-11p, Sat 10a-11p, Sun 10a-2p (Brunch Only)',
    // hoursShort: 'Mon-Sat: 9am-11pm, Sun: 9am-8pm',
    // notice: "Pending NYS Liquor License",
    // leaguesShort:"3-5pm, 5:20-7:20pm, and 7:40-close",
    nonPeakRates: {
      // range: 'Mon - Fri 9am - 3pm',
      range: '',
      bays: [
        { name: 'Hole 1', price: '$50 per hour' },
        { name: 'Hole 2', price: '$50 per hour' },
        { name: 'Hole 3', price: '$50 per hour' },
        { name: 'Hole 4', price: '$50 per hour' },
        { name: 'Hole 5', price: '$50 per hour' },
        { name: 'Hole 6', price: '$50 per hour' },
        { name: 'VIP Suite (Private)', price: '$70 per hour' },
        {
          name: 'Spartan Room',
          price: '$80 per hour',
        },
      ],
    },
    peakRates: {
      // range: 'Mon - Fri 3pm - 11pm (Sat, Sun & Holidays All Day)',
      range: '',
      bays: [
        { name: 'Hole 1', price: '$60 per hour' },
        { name: 'Hole 2', price: '$60 per hour' },
        { name: 'Hole 3', price: '$60 per hour' },
        { name: 'Hole 4', price: '$60 per hour' },
        { name: 'Hole 5', price: '$60 per hour' },
        { name: 'Hole 6', price: '$60 per hour' },
        { name: 'VIP Suite (Private)', price: '$100 per hour' },
        { name: 'Spartan Room', price: '$80 per hour' },
      ],
    },
    // mealeo: 'https://order.getinthebunker.menu/r/67290',
    coordinates: { lat: 43.090492845567816, lng: -75.31534266256405 },
    promotions: [
      {
        title: 'VIP Suite Discount',
        body: 'VIP Suite $70 Monday-Thursday with 4 players or less',
      },
      {
        title: 'BOGO Golf, Drinks, & Apps',
        body: 'Wednesday-Friday 4-6pm',
      },
    ],
    amenities: [
      { icon: TrackmanBaysSvg, title: '8 TRACKMAN Bays' },
      { icon: FullBarSvg, width: '80px', title: 'Full Bar' },
      { icon: FullFoodSvg, title: 'Full Food Menu' },
      { icon: CateringSvg, width: '75px', title: 'Catering' },
      { icon: GolfLessonsSvg, title: 'Golf Lessons' },
      { icon: EventsSvg, title: 'Events' },
      { icon: GiftCardsSvg, title: 'Gift Cards' },
      { icon: HappyHourSvg, title: 'Happy Hour' },
    ],
  },

  /*
   *    Mohawk Harbor
   */
  {
    images: [
      mohawkharbor_main,
      mohawkharbor_1,
      mohawkharbor_2,
      mohawkharbor_3,
      mohawkharbor_4,
    ],
    id: 'mohawkharbor',
    menus: [
      { pdf: TB_MH_BRUNCH_MENU, name: 'Brunch Menu' },
      { pdf: TB_MH_MENU, name: 'Main Menu' },
      { pdf: TB_MH_DESSERT_MENU, name: 'Dessert Menu' },
      { pdf: TB_KIDS_MENU, name: 'Kids Menu' },
      { pdf: TB_MH_SPECIALTY_DRINKS_MENU, name: 'Specialty Drinks' },
    ],

    url: 'https://thebunker-teefindr-live.web.app/location/thebunker/thebunkermohawkharbor-VX2ZFJ7ucPkOMv8JYKlV',
    email: 'info@GetInTheBunker.golf',
    name: 'Mohawk Harbor',
    address: '221 Harborside Dr, Schenectady, NY 12305',
    phone: '(518) 348-9993',
    // winter hours
    // hoursFull: 'Mon-Sat 9am-11pm Sun 9am-8pm',

    // summer hours
    hoursFull:
      'Closed Mon, Tue-Thur 4p-10p, Fri 11p-12a, Sat 10a-12a, Sun 10a-8p (Brunch 10a-2p)',

    // hoursShort: 'Mon-Sat: 9am-11pm, Sun: 9am-8pm',
    // notice: "Pending NYS Liquor License",
    // leaguesShort:"3-5pm, 5:20-7:20pm, and 7:40-close",
    nonPeakRates: {
      // range: 'Mon - Fri 9am - 3pm',
      range: '',
      bays: [
        { name: 'Bay 1', price: '$60 per hour' },
        { name: 'Bay 2', price: '$60 per hour' },
        { name: 'Vegas Room', price: '$80 per hour' },
        { name: 'Players Club', price: '$90 per hour' },
        { name: 'Players Club Darts', price: '$50 per hour' },
        { name: 'Dart Board 1', price: '$20 per hour' },
        { name: 'Dart Board 2', price: '$20 per hour' },
        { name: 'Dart Board 3', price: '$20 per hour' },
      ],
    },
    peakRates: {
      // range: 'Mon - Fri 3pm - 11pm (Sat, Sun & Holidays All Day)',
      range: '',
      bays: [
        { name: 'Bay 1', price: '$70 per hour' },
        { name: 'Bay 2', price: '$70 per hour' },
        { name: 'Vegas Room', price: '$80 per hour' },
        { name: 'Players Club', price: '$90 per hour' },
        { name: 'Players Club Darts', price: '$50 per hour' },
        { name: 'Dart Board 1', price: '$20 per hour' },
        { name: 'Dart Board 2', price: '$20 per hour' },
        { name: 'Dart Board 3', price: '$20 per hour' },
      ],
    },
    // mealeo: 'https://order.getinthebunker.menu/r/67290',
    coordinates: { lat: 42.82568003210177, lng: -73.93242664418067 },
    promotions: [],
    amenities: [
      { icon: TrackmanBaysSvg, title: '4 TRACKMAN Bays' },
      { icon: IndoorOutdoorBarSvg, title: 'Indoor/Outdoor Bar' },
      { icon: OutdoorSeatingSvg, title: 'Outdoor Seating' },
      { icon: OutdoorGrillSvg, title: 'Outdoor Grill' },
      { icon: FullBarSvg, width: '80px', title: 'Full Bar' },
      { icon: FullFoodSvg, title: 'Full Food Menu' },
      { icon: DartsSvg, title: 'Darts' },
      { icon: CateringSvg, width: '75px', title: 'Catering' },
      { icon: GolfLessonsSvg, title: 'Golf Lessons' },
      { icon: EventsSvg, title: 'Events' },
      { icon: GiftCardsSvg, title: 'Gift Cards' },
      { icon: HappyHourSvg, title: 'Happy Hour' },
    ],
  },

  /*
   *    Saratoga
   */
  {
    images: [
      'https://storage.googleapis.com/thebunker-assets/thebunker/saratoga/IMG-86.jpg',
      'https://storage.googleapis.com/thebunker-assets/thebunker/saratoga/main.png',
      'https://storage.googleapis.com/thebunker-assets/thebunker/saratoga/IMG-9.jpg',
      'https://storage.googleapis.com/thebunker-assets/thebunker/saratoga/IMG-1.jpg',
      'https://storage.googleapis.com/thebunker-assets/thebunker/saratoga/IMG-10.jpg',
    ],
    id: 'saratoga',
    menus: [
      { pdf: TB_S_BRUNCH_MENU, name: 'Brunch Menu' },
      {
        pdf: TB_S_MENU,
        name: 'Main Menu',
      },
      {
        pdf: TB_S_DESSERT_MENU,
        name: 'Dessert Menu',
      },
      {
        pdf: TB_S_KIDS_MENU,
        name: 'Kids Menu',
      },
      { pdf: TB_S_SPECIALTY_DRINKS_MENU, name: 'Specialty Drinks' },
    ],
    url: '/location/saratoga',
    email: 'info@GetInTheBunker.golf',
    name: 'Saratoga',
    address: '307 Broadway, Saratoga Springs, NY 12866',
    phone: '(518) 245-6480',
    // hoursFull: 'M-Th 11am-11pm, Fri/Sat 9am-11pm, Sun 9am-8pm',
    hoursFull:
      'Mon-Thur 11a-11p, Fri 11a-12a, Sat 10a-12a, Sun 10a-8p (Brunch 10a-2p)',
    nonPeakRates: {
      range: '',
      bays: [
        {
          name: 'BAY 1',
          price: '$60 per hour',
        },
        {
          name: 'BAY 2',
          price: '$60 per hour',
        },
        {
          name: 'BAY 3',
          price: '$60 per hour',
        },
        {
          name: 'BAY 4',
          price: '$60 per hour',
        },
        {
          name: 'BAY 5',
          price: '$65 per hour',
        },
        {
          name: 'BAY 6',
          price: '$65 per hour',
        },
        {
          name: 'VIP SUITE',
          price: '$160 per hour',
        },
      ],
    },
    peakRates: {
      range: '',
      bays: [
        {
          name: 'BAY 1',
          price: '$75 per hour',
        },
        {
          name: 'BAY 2',
          price: '$75 per hour',
        },
        {
          name: 'BAY 3',
          price: '$75 per hour',
        },
        {
          name: 'BAY 4',
          price: '$75 per hour',
        },
        {
          name: 'BAY 5',
          price: '$80 per hour',
        },
        {
          name: 'BAY 6',
          price: '$80 per hour',
        },
        {
          name: 'VIP SUITE',
          price: '$160 per hour',
        },
      ],
    },
    coordinates: {
      lat: 43.07896181183951,
      lng: -73.78634854815526,
    },
    promotions: [],
    amenities: [
      {
        icon: TrackmanBaysSvg,
        title: '7 TRACKMAN Bays',
      },
      {
        icon: FullBarSvg,
        title: '2 Full Bars',
      },
      {
        icon: FullFoodSvg,
        title: 'Full Food Menu',
      },
      {
        icon: TwoFloorsSvg,
        title: '2 Floors',
      },
      {
        icon: CateringSvg,
        width: '70px',
        title: 'Catering',
      },
      {
        icon: GolfLessonsSvg,
        title: 'Gof Lessons',
      },
      {
        icon: EventsSvg,
        title: 'Events',
      },
      {
        icon: DartsSvg,
        title: 'Darts',
      },
      {
        icon: GiftCardsSvg,
        title: 'Gift Cards',
      },
    ],
  },
];

export default locations;
